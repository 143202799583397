define("discourse/plugins/discourse-onedrive-picker/discourse/onedrive-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("onedrive-picker", {
      path: "/onedrive-picker",
      resetNamespace: true
    });
  }
});