define("discourse/plugins/discourse-onedrive-picker/discourse/initializers/discourse-onedrive-picker", ["exports", "discourse/lib/plugin-api", "discourse/lib/load-script", "bootbox", "discourse-common/lib/get-url"], function (_exports, _pluginApi, _loadScript, _bootbox, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-onedrive-picker";
  function initializeDiscourseOneDrivePicker(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    function openDrive(e) {
      if (!siteSettings.discourse_onedrive_picker_azure_client_id) {
        _bootbox.default.alert(I18n.t("onedrive.picker.error"));
        return;
      }
      (0, _loadScript.default)("/plugins/discourse-onedrive-picker/javascripts/OneDrive.js").then(() => {
        var odOptions = {
          clientId: siteSettings.discourse_onedrive_picker_azure_client_id,
          action: "share",
          viewType: "all",
          multiSelect: true,
          advanced: {
            redirectUri: (0, _getUrl.getAbsoluteURL)("/onedrive-picker"),
            createLinkParameters: {
              type: "view",
              scope: "anonymous"
            }
          },
          success: function (files) {
            let urls = files.value.map(file => siteSettings.discourse_onedrive_picker_use_markdown_url ? `[${file.name}](${file.permissions[0].link.webUrl})` : file.permissions[0].link.webUrl);
            return e.addText(`${urls.join("\n")}\n`);
          },
          cancel: function () {},
          error: function (error) {}
        };
        OneDrive.open(odOptions);
      });
    }
    api.onToolbarCreate(function (toolbar) {
      toolbar.addButton({
        trimLeading: true,
        id: "buttonID",
        group: "insertions",
        icon: "cloud",
        title: "onedrive.picker.title",
        perform: e => openDrive(e)
      });
    });
  }
  var _default = _exports.default = {
    name: "discourse-onedrive-picker",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.discourse_onedrive_picker_enabled) {
        (0, _pluginApi.withPluginApi)("0.10.1", initializeDiscourseOneDrivePicker);
      }
    }
  };
});