define("discourse/plugins/discourse-onedrive-picker/discourse/controllers/onedrive-picker", ["exports", "@ember/controller", "discourse/lib/load-script"], function (_exports, _controller, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    init() {
      this._super(...arguments);
      (0, _loadScript.default)("/plugins/discourse-onedrive-picker/javascripts/OneDrive.js");
    }
  });
});